import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import HeaderBanner from '../../../components/header-banner';
import app from "../../../assets/images/mobile_app1.jpg";

import PhoneInput from "react-phone-input-2";
import postData from '../../../hooks/useFetch';
import { notification } from 'antd';


function DeleteAccount() {
    const [phone, setPhone] = useState("")
    const [disable, setDisable] = useState(false)
    const page = {
        title: "Rudi Account Deletion",
        description:
            "Unlock a world of convenience at your fingertips with Our Rudi mobile app",
    };

    const submit = () => {
        setDisable(true)

        postData({
            "data": {
                "phone": phone
            },
            "service": "delete_account"
        }).then((data) => {
            notification.open({
                message: "Account deletion",
                description: data.message,
                duration: 5,
            });
            setPhone("")
            setDisable(false)
        });
    }

    return (
        <div>
            <Helmet>
                <title>{page.title}</title>
                <meta name="title" content={page.title} />
                <meta name="description" content={page.description} />
                <meta property="og:title" content={page.title} />
                <meta property="og:description" content={page.description} />
                <meta name="twitter:title" content={page.title} />
                <meta name="twitter:description" content={page.description} />
            </Helmet>
            <HeaderBanner
                image={app}
                title="Rudi Account Deletion"
                description="Initiate accoutn deletion"
            />
            <div className='max-w-2xl py-20 mx-auto'>
                <p>Phone Number</p>
                <div className="mt-2 phone-number">
                    <PhoneInput
                        countryCodeEditable={false}
                        country={"ke"}
                        enableSearch={true}
                        required={true}
                        value={phone}
                        onChange={(e) => {
                            setPhone(e);
                        }}
                    />
                </div>

                <button disabled={disable} className='w-full p-3 mt-3 text-white rounded rudi-bg' onClick={() => submit()}>Submit</button>
            </div>
        </div>
    )
}

export default DeleteAccount