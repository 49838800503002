import React from "react";
import { Helmet } from "react-helmet";
import HeaderBanner from "../../../components/header-banner.js";
import blogbg from "../../../assets/images/banner/blog.jpg";
import { useLocation } from "react-router-dom";
import { admin_post } from "../../../hooks/useFetch.js";
import { Markup } from "interweave";
import BlogItem from "./blog-item.js";

function BlogDetail() {
  document.title = "Blog Post"


  let location = useLocation();
  const [posts, setPosts] = React.useState([])

  const blogs = () => {
    admin_post({
      service: "blog_posts",
      data: {
        offset: "0",
        items: "10",
      },
    }).then((resp) => {
      if (resp.success === 1) setPosts(resp.data);
    });
  };



  const [post, setPost] = React.useState({})
  const [pid, setPid] = React.useState("");


  const getData = (slug) => {
    admin_post({
      "service": "blog_post",
      "data": {
        slug
      }
    }).then((resp) => {
      if (resp.success === 1) setPost(resp.data)
    })
  }

  React.useEffect(() => {
    window.scrollTo(0, 0);
    let id = location.pathname.substring(1);
    id = id.length > 1 ? id.split("/")[1] : "";
    if (pid !== id) {
      setPid(id);
      getData(id);
    }

    blogs()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


  return (
    <div>
      {Object.keys(post).length > 0 && <Helmet>
        <title>{post.title}</title>
        <meta name="title" content={post.title} />
        <meta name="description" content={post.description} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.description} />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.description} />
      </Helmet>}

      <HeaderBanner
        image={blogbg}
        title={post.title}
        description=""
      />
      <div>
        {Object.keys(post).length > 0 && <div className="container pt-16 mx-auto">
          <div className="max-w-2xl mx-auto">
            <p className="mb-6 font-serif text-5xl font-thin ">{post.title}</p>
          </div>
          <img src={post.image} alt={post.title} width={'100%'} className="md:px-36" /></div>}

        <div className="container mx-auto">
          <div className="gap-8 mt-8 mb-16 font-serif lg:mx-32">
            {Object.keys(post).length > 0 &&
              <div className="flex-1">
                <div className="py-3 text-2xl">
                  <Markup content={post.description} />
                </div>
              </div>}
          </div>

          <div className="grid grid-cols-1 divide-x lg:grid-cols-3 md:grid-cols-2">
            {posts.map((post, i) => <BlogItem post={post} key={i} />)}
          </div>
        </div>


      </div>
    </div>
  );
}

export default BlogDetail;
